<template>
  <div class="k-gold">
    <div class="top-info">
      <img src="https://konewx.obs.cn-south-1.myhuaweicloud.com/yinchuang/staticResource/tyb/ty/k-gold/k-gold-ok.png" class="ok-img" />
      <div class="buy-ok">购买成功</div>
      <div class="buy-tit">{{product_name}}</div>
    </div>
    <!--  -->
    <div v-if="imgUrl" class="content-div">
      <div class="er-text">长按识别二维码</div>
      <div class="kf-text">添加微信客服，立即领取</div>
      <div id="qrCode" class="img-er" ref="qrCodeDiv"></div>
    </div>
    <div v-else @click="backs" class="back-btn">返回首页</div>
  </div>
</template>
<script>
  import qs from 'qs'
  import QRCode from 'qrcodejs2';
  export default {
    name: 'kGold',
    data(){
      return {
        imgUrl: '',
        product_name: ''
      }
    },
    created(){
      let _this = this, product_type=2;
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      //判断是否已添加客服
      _this.axios.get('/h5/coins/isAddWechat',{
        params: {
          mac: _this.$route.query.mac,
          union_id: _this.$route.query.union_id,
          product_id: _this.$route.query.product_id
        }
      }).then(data => {
        // if(data.return_code != 200){
        //   _this.$toast.clear();
        //   _this.$toast('获取失败');
        //   return Promise.reject();
        // }
        if(data.data.is_add_wechat==1){ //添加了企业微信
          if(data.data.product_type==2){ //虚拟会员
            setTimeout(()=>{
              wx.miniProgram.redirectTo({
                url: '/k_gold/get_card?exchange_id=' + this.$route.query.exchange_id
              })
            },500)
          }
          else{
            setTimeout(() => {
              wx.miniProgram.redirectTo({
                url: `/k_gold/exchange_status?types=3&exchange_id=${this.$route.query.exchange_id}&company_id=${this.$route.query.store_id}`
              })
            },500)
          }
        }
        else{
          // if(data.data.product_type==1){ //红包
          //   setTimeout(() => {
          //     wx.miniProgram.navigateTo({
          //       url: `/k_gold/qw_box?urls=${data.data.qrcode_url}`
          //     })
          //   },500)
          // }
          // else{
            setTimeout(() => {
              wx.miniProgram.redirectTo({
                url: `/k_gold/qw_add?urls=${data.data.qrcode_url}`
              })
            },500)
          // }
        }
        
        

        // _this.product_name = data.data.product_name;
        // if(!data.data.is_add_wechat){
        //   _this.imgUrl = true;
        //   _this.$nextTick(() => {
        //     new QRCode(_this.$refs.qrCodeDiv, {
        //       text: data.data.qrcode_url,
        //       width: 200,
        //       height: 200,
        //       colorDark: "#333333", //二维码颜色
        //       colorLight: "#ffffff", //二维码背景色
        //       correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        //     })
        //   })
         
        //   return Promise.reject();
        // }
        // product_type = data.data.product_type; //2会员，3优惠券
        //扣金币
        // return _this.axios.post('/h5/coins/exchangeProduct',
        //   qs.stringify({
        //     mac: _this.$route.query.mac,
        //     user_id: _this.$route.query.user_id,
        //     union_id: _this.$route.query.union_id,
        //     product_id: _this.$route.query.product_id,
        //     order_id: _this.$route.query.order_id,
        //     pay_type: _this.$route.query.pay_type,
        //     company_id: _this.$route.query.company_id,
        //     guest_id: _this.$route.query.guest_id,
        //     card_id: _this.$route.query.card_id
        //   })
        // )
      
      })
      // .then(data => {
      //   if(data.return_code != 200){
      //     _this.$toast(data.return_msg)
      //     return Promise.reject();
      //   }
      //   _this.$toast.clear();
      //   _this.$toast('兑换成功');
      //   //虚拟会员
      //   if(product_type==2){
      //     setTimeout(()=>{
      //       wx.miniProgram.navigateTo({
      //         url: '/k_gold/get_card?orderId=' + this.$route.query.order_id
      //       })
      //     },500)
      //     // return Promise.reject();
      //   }
      //   else{
      //     setTimeout(() => {
      //       wx.miniProgram.navigateTo({
      //         url: `/k_gold/exchange_status?types=3&title=${_this.product_name}&company_id=${_this.$route.query.company_id}&exchange_id=${data.data.exchange_id}`
      //       })
      //     },500)
      //   }
      
      // })
      .catch(err => {
        _this.$toast.clear();
      })
    },
    methods: {
      backs: function(){
        wx.miniProgram.redirectTo({
          url: '/k_gold/index?companyId=' + this.$route.query.store_id
        })
      }
    }
  }
</script>
<style scoped>
  .k-gold{
    min-height: 100vh;
    background-color: #F8F7FC;
  }
  .top-info{
    width: 100vw;
    height: 230px;
    padding-top: 200px;
    text-align: center;
    background-color: #FFCF2E;
    border-radius: 0 0 12px 12px;
  }
  .ok-img{
    display: block;
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  .buy-ok,.er-text{
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #222222;
    margin: 10px 0;
  }
  .buy-tit{
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    line-height: 22px;
  }
  .content-div{
    position: relative;
    width: 345px;
    padding: 30px 0;
    margin: -50px auto 0 auto;
    z-index: 2;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 10px;
  }
  .er-text{
    margin: 30px auto 4px auto;
  }
  .kf-text{
    font-size: 16px;
    font-weight: 400;
    color: #F54043;
    line-height: 22px;
  }
  .img-er{
    width: 200px;
    height: 200px;
    margin: 20px auto;
  }
  .back-btn{
    width: 100px;
    padding: 10px 20px;
    text-align: center;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 6px;
  }
</style>